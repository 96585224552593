import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import ModalService from "components/elements/modal/ModalService";
import UserService from "services/UserService";
import MappedLocation from "app/sales-agent/modules/warranty-pickup/MappedLocation";
import { Button } from "antd";
import styled from "styled-components";

const StyledMapButton = styled(Button)`
  font-size: 13px;
  padding: 4px 8px;
  height: 30px;
  line-height: 1;
  margin-left: 37px;
  @media (max-width: 600px) {
    font-size: 8px;
    padding: 3px 6px;
    height: 28px;
  }
`;
const StyledLayout = styled(Layout.Sider)`
  height: 100vh;
  /* margin-right: 24px; */
`;

const SideBar = ({ menu, isSideBarOpen }) => {
  const { pathname } = useLocation();
  const [manageLocation, setManageLocation] = useState("");

  const locationSet = UserService?.getCurrentUserLoginData()?.locationSet;
  const warehouseId = UserService?.getCurrentUserLoginData()?.partnerId;
  const handleOpenModal = () => {
    ModalService.open(MappedLocation, {
      warehouseId,
    });
  };
  useEffect(() => {
    //console.log(pathname !== '' ? pathname.substring(3, pathname.lastIndexOf('/')):"");
    setManageLocation(
      pathname !== "" ? pathname.substring(3, pathname.lastIndexOf("/")) : ""
    );
  }, []);
  return (
    <>
      {isSideBarOpen === true ? (
        <StyledLayout
          breakpoint={"lg"}
          theme="light"
          collapsedWidth={0}
          trigger={null}
          width={280}
        >
          {menu}
          {manageLocation?.toString() === "/sales-agent" &&
            Boolean(!locationSet) && (
              <StyledMapButton
                type="primary"
                size="small"
                onClick={handleOpenModal}
              >
                Map Location
              </StyledMapButton>
            )}
        </StyledLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default SideBar;
