export const WarrantyLinks = [
  {
    name: "Warranty Process",
    icon: "icofont-home",
    route: "/warranty/dashboard",
  },
  {
    name: "Pickup Request",
    icon: "icofont-vehicle-delivery-van",
    route: "/warranty/pickup-request",
  },
  {
    name: "Customer Approval",
    icon: "icofont-user",
    route: "/warranty/customer-approval",
  },
  {
    name: "Warranty Collection",
    icon: "icofont-info",
    route: "/warranty/warranty-collection",
  },
  {
    name: "Get All Sell Out",
    icon: "icofont-chart-histogram",
    route: "/warranty/get-all-sell-out",
  },
];
