import React, { useEffect, useState } from "react";
import { ListView } from "layouts";
import { PageHeaderComponent, Loader, Table } from "components/elements";
import { format } from "date-fns";
import { MenuOutlined } from "@ant-design/icons";
import { useHttp } from "hooks";
import { Button, Dropdown, Menu } from "antd";
import SwapModal from "./SwapModal";
import ReturnModal from "./ReturnModal";
import ModalService from "components/elements/modal/ModalService";
import UserService from "services/UserService";

const SellOutRoutes = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();
  const [units, setUnits] = useState([]);
  const [selectedSKURequests, setselectedSKURequests] = useState([]);
  const openSwapModal = (id, InventoryId, title) => {
    ModalService.open(SwapModal, {
      id,
      InventoryId,
      title,
      refresh: getInven,
      selectedSKURequests,
    });
  };
  const openMakeASaleModal = () => {
    ModalService.open(ReturnModal, {
      selectedSKURequests,
      refresh: getInven,
    });
  };
  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getInven();
  }, []);

  const handlInventoryData = ({ data: { requests } }) => {
    let tempData = [];
    requests?.map((el) => {
      return tempData.push({
        _id: el?._id,
        SoldDate: format(new Date(el?.soldDate), "dd MMM yyyy"),
        model: el?.model?.modelName,
        model_id: el?.model?._id,
        variant:
          el?.variant[0]?.ram !== undefined
            ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
            : "",
        imei: el?.imei && el?.imei !== undefined ? el?.imei : "-",
        serial_number:
          el?.serialNumber && el?.serialNumber !== undefined
            ? el?.serialNumber
            : "-",
        sales_agent: el?.sales_agent?.fname + " " + el?.sales_agent?.lname,
        warehouse: el?.warehouse?.name !== undefined && el?.warehouse?.name,
        invoiceContactName: el?.invoice ? el?.invoice?.contactName : " -",
        invoiceContactPhone: el?.invoice ? el?.invoice?.contactPhone : " -",
        assignedColor: el?.assignedColor[0]?.name ?? null,
        action: [el?._id, el?.sold, el?.model?.modelName, el?.model?._id, el],
        status: el?.current_status[0]?.name?.toUpperCase(),
      });
    });
    setUnits(tempData);
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: requests.length,
    });
  };

  const getInven = async () => {
    getInventory(`get_all_sellouts`, "GET", null, handlInventoryData);
  };

  const columns = [
    // {
    //   label: "ACTION",
    //   name: "action",
    //   options: {
    //     filter: false,
    //     download: false,
    //     print: false,
    //     sort: false,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "left" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           <div className="list-icons">
    //             <Dropdown
    //               overlay={
    //                 <Menu>
    //                   <Menu.Item
    //                     key="1"
    //                     onClick={() => openMakeASaleModal(value[0], value[4])}
    //                   >
    //                     Return Device
    //                   </Menu.Item>
    //                   {/* <Menu.Item
    //                     key="2"
    //                     onClick={() => openSwapModal(value[0], value[4])}
    //                   >
    //                     Swap
    //                   </Menu.Item> */}
    //                 </Menu>
    //               }
    //               trigger={["click"]}
    //               placement="bottomRight"
    //             >
    //               <Button
    //                 className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
    //                 icon={<MenuOutlined />}
    //               />
    //             </Dropdown>
    //           </div>
    //         </>
    //       );
    //     },
    //   },
    // },
    {
      label: "Sold Date",
      name: "SoldDate",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <h6 className="mb-0">
                {format(new Date(value), "dd")} {format(new Date(value), "MMM")}
              </h6>
              <div className="font-size-sm text-muted line-height-1">
                {format(new Date(value), "yyyy")}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "model_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "model",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },

    {
      label: "Color",
      name: "assignedColor",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : <p>Color not set</p>}</>;
        },
      },
    },
    {
      label: "Sale Agent Name",
      name: "sales_agent",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Warehouse",
      name: "warehouse",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Customer Name",
      name: "invoiceContactName",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Customer Number",
      name: "invoiceContactPhone",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
  ];
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected.map(
      ({ dataIndex }) => units[dataIndex]
    );
    let tempObjData = [];
    selectedData.map((el) => {
      tempObjData.push({
        _id: el?._id,
        model_id: el?.model_id,
      });
    });
    setselectedSKURequests(tempObjData);
  };
  return (
    <>
      {loadingInventory && <Loader />}
      {!loadingInventory && (
        <ListView
          pageHeader={
            <PageHeaderComponent
              title="Get All Sell Out"
              subtitle=""
              icon="icofont-chart-histogram"
            />
          }
          table={
            <Table
              title={"Sell Out"}
              data={pageData.rowData}
              columns={columns}
              option={{
                selectableRows: "multiple",
                onRowSelectionChange: handleRowSelectionChange,
                customToolbarSelect: (
                  selectedRows,
                  displayData,
                  setSelectedRows
                ) => (
                  <div>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      onClick={() => openMakeASaleModal()}
                    >
                      Return Device
                    </button>
                    &emsp;
                  </div>
                ),
              }}
            />
          }
          data={pageData.rowData}
        />
      )}
    </>
  );
};

export default SellOutRoutes;
