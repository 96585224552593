import React, { useState, useEffect } from "react";
import { useHttp } from "hooks";
import { formatDate } from "utils";
import { Loader, PageHeaderComponent, Table } from "components/elements";
import { ListView } from "layouts";
import { IconButton } from "@material-ui/core";
import ModalService from "components/elements/modal/ModalService";
import MakeItSales from "./MakeItSalesModel";
import UserService from "services/UserService";

export default function Accessories() {
  const [requests, setRequests] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const {
    error,
    loading,
    sendHttpRequest: getShippingCompanyRequests,
  } = useHttp();

  function getShippingCompanyRequestsData() {
    return getShippingCompanyRequests(
      `get_accessories_ba/${UserService?.getCurrentUserLoginData()?.partnerId}`,
      "GET",
      null,
      handleData
    );
  }

  useEffect(() => {
    getShippingCompanyRequestsData();
  }, []);

  useEffect(() => {
    setRowCount(requests?.length);
  }, [requests]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);

  const openModelModal = (sid) => {
    ModalService.open(MakeItSales, {
      refresh: () => getShippingCompanyRequestsData(),
      val: sid,
    });
  };

  const handleData = ({ success, data: { find } }) => {
    let tempData = [];
    if (success === true) {
      find?.map((val) => {
        return tempData.push({
          accessoryName: val?.accessory?.name || "-", // Safe navigation for `model`
          dispatchedQuantity: val?.dispatchQuantity || 0,
          dispatchTime: val?.dispatchTime || "-",
          name: val?.sku?.name || "-",
          action: {
            id: val?._id,
            accessoryName: val?.accessory?.name,
            name: val?.sku?.name,
            dispatchedQuantity: val?.dispatchQuantity || 0,
          },
        });
      });
    }
    if (success === false) {
      setRequests([]);
    }
    setRequests(tempData);
  };
  const columns = [
    {
      label: "Action",
      name: "action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                color="primary"
                size="small"
                aria-label="view"
                component="span"
                onClick={() => openModelModal(value)}
              >
                <i className="icofont icofont-expand" />
              </IconButton>
            </>
          );
        },
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      label: "Accessory Name",
      name: "accessoryName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Dispatched Quantity",
      name: "dispatchedQuantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Dispatch Date",
      name: "dispatchTime",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? formatDate(value) : "-";
        },
      },
    },
  ];
  const routes = [
    {
      path: "",
      breadcrumbName: "Home",
    },
    {
      path: "",
      icon: "icofont-vehicle-delivery-van",
      breadcrumbName: "Accessories",
    },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <ListView
          data={requests}
          pageHeader={
            <PageHeaderComponent
              routes={routes}
              title="Accessories"
              subtitle=""
              icon="icofont-vehicle-delivery-van"
            />
          }
          table={
            <Table
              title={"Accessories"}
              data={requests}
              columns={columns}
              option={{
                customToolbar: () => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginBottom: "16px",
                        fontSize: "18px",
                        marginLeft: "-58px",
                      }}
                    >
                      Total Rows: <b>{rowCount}</b>
                    </div>
                  </div>
                ),
                onTableChange: (action, tableState) => {
                  if (action === "filterChange" || action === "search") {
                    setFilteredData(
                      tableState.displayData.map((row) => row.data)
                    );
                  }
                },
              }}
            />
          }
        />
      )}
    </>
  );
}
