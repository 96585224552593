import React, { useState, useRef, useEffect } from "react";
import Modal from "components/elements/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import ImageLogoBadili from "assets/images/pdfLogo/badili-logo.jpg";
import html2pdf from "html2pdf.js";
import { format } from "date-fns";
import "./DownloadDeliveryNote.css";
import UserService from "services/UserService";

export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency:
      UserService.getCurrentUserLoginData().country === "ug"
        ? "UGX"
        : UserService.getCurrentUserLoginData().country === "ke"
        ? "KES"
        : UserService.getCurrentUserLoginData().country === "tz"
        ? "TZS"
        : UserService.getCurrentUserLoginData().country === "in"
        ? "INR" // India
        : UserService.getCurrentUserLoginData().country === "ng"
        ? "NGN" // Nigeria
        : " ", 
  });

  return nf.format(number);
};

const ProcessWarrantyMainPickups = (props) => {
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getOrderNumber } = useHttp();
  const [OrderNumber, setOrderNumber] = useState("");
    const [selecteddispatchMode, setSelecteddispatchMode] = useState({
      id: "",
      name: "",
    });
  const {
    error: errordispatchModes,
    loading: assigningdispatchMode,
    sendHttpRequest: assigndispatchMode,
  } = useHttp();
  const country = UserService.getCurrentUserLoginData().country;

  const pdfRef = useRef(null);
  useEffect(() => {
    getOrderNumber(
      `rnote_order_number/${country}`,
      "GET",
      null,
      ({ data: { note } }) => {
        setOrderNumber(note);
      }
    );
  }, []);
  const sendConsignmentFun = async () => {
    const randomNumber = Math.floor(Math.random() * 1000); // Adjust as needed
    const filename = `downloaded-badili-delivery-note-${randomNumber}.pdf`;
    const htmlContent = document.getElementById("htmlContentToConvert");
    const options = {
      margin: 5, // Adjust margin
      filename: filename,
      html2canvas: { scale: 1.5 }, // Adjust scale
      pagebreak: { mode: "avoid-all" },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    if (htmlContent) {
      html2pdf().from(htmlContent).set(options).save();
    } else {
      console.error("HTML content not found. Unable to generate PDF.");
    }
  };

  const handleDownloadPDF = () => {
     
    assigndispatchMode(
      `dispatch_complete_warranty`,
      "PUT",
      {
        deviceId: props?.id,
        userId: UserService.getCurrentUserLoginData().id,
        dispatchMode: selecteddispatchMode,
      },
      ({ msg, success }) => {
        if (success) {
          sendConsignmentFun();
        }
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        props.refresh();
        props.close();
      }
    );
  };
  const handledispatchModeChange = (e) => {
    const selectedId = e.target.value;
    // const selectedName =
    //   props.dispatchModes.find((wh) => wh._id === selectedId)?.name || "";
    setSelecteddispatchMode(selectedId);
  };
  return (
    <>
      <Modal
        close={props.close}
        onAccept={() => {
          handleDownloadPDF();
        }}
        onReject={props.close}
        title={`Generate Warranty Dispatch Note`}
        form="downloadnoteform"
        AcceptText="Submit & Download"
        size="lg"
        footerOntop={true}
        invoiceDown={true}
        invoiceDownContent={true}
      >
       <form onSubmit={(e) => e.preventDefault()} id="assign_dispatchMode">
          <div
            className="form-group-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="form-group" style={{ width: "300px" }}>
              <label>
                Select Dispatch Mode<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control form-control-sm"
                onChange={handledispatchModeChange}
                name="via"
                defaultValue=""
                required
              >
                <option value="" selected disabled>
                  Select a Dispatch Mode
                </option>
                <option value="LRF Dispatch" >
                LRF Dispatch
                </option>
                <option value="On Premise Handover" >
                On Premise Handover
                </option>
                <option value="Dispatched Via Rider" >
                Dispatched Via Rider
                </option>
                {/* {props?.dispatchModes?.map(({ _id, name }) => (
                  <option value={_id} key={_id}>
                    {name}
                  </option>
                ))} */}
              </select>
            </div>
          </div>
        </form>
        <div id="htmlContentToConvert" className="scrllView" ref={pdfRef}>
          <div className="receipt-content ">
            <div className="container bootstrap snippets bootdey">
              <div className="row">
                <div className="col-md-12">
                  <div className="invoice-wrapper">
                    <div
                      className="intro"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={ImageLogoBadili}
                        width={155}
                        height={65}
                        alt="Badili Logo"
                      />
                      <div style={{ marginLeft: "20px" }}>
                        {country === "ke" && (
                          <>
                            <p className="ft16">Kenya;</p>
                            <p className="ft16">P.O Box 8744-00100,</p>
                            <p className="ft16">Nairobi.</p>
                            <p className="ft16">Chiromo Court, Waiyaki Way</p>
                          </>
                        )}
                        {country === "ug" && (
                          <>
                            <p className="ft16">Badili Uganda SMC Limited</p>
                            <p className="ft16">
                              Office No 301 , Level 3- Kampala Boulevard
                            </p>
                            <p className="ft16">
                              Kampala Road - Kampala, Uganda.
                            </p>
                          </>
                        )}
                        {country === "tz" && (
                          <>
                            <p className="ft16">Badili Tanzania LTD</p>
                            <p className="ft16">Chole Plaza,</p>
                            <p className="ft16">
                              26 Chole Rd, Dar es Salaam -Tanzania
                            </p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="payment-details">
                      <p
                        className="s3"
                        style={{
                          //  paddingTop: "1pt",
                          textAlign: "center",
                        }}
                      >
                        <h6 style={{ textDecoration: "underline" }}>
                          Warranty Dispatch Note
                        </h6>
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <p
                            className="s2"
                            style={{
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            {props?.transfer?.deliveryId}
                          </p>
                        </div>
                        <div className="col-sm-6 delAddess"></div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-sm-6">
                        <strong>Order: </strong>
                        <br />
                        <p>{OrderNumber}</p>
                      </div>
                      <div className="col-sm-6">
                        <strong>Return Date: </strong>
                        <br />
                        <p>{format(new Date(), "dd-MMM-yyyy HH:mm a")}</p>
                      </div>
                    </div>
                    <div className="line-items">
                      <table
                        style={{
                          borderCollapse: "collapse",
                          marginLeft: "-7.2695pt",
                          marginBottom: "140px",
                        }}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr style={{ height: "21pt" }}>
                            <td
                              style={{
                                width: "329pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "90pt",
                                  paddingRight: "90pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                <strong>Product</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "133pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "24pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                <strong>Warranty Cost</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "133pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "24pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                <strong>IMEI</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "74pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "14pt",
                                  paddingRight: "13pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                <strong>Units</strong>
                              </p>
                            </td>
                          </tr>

                          <tr bgcolor="#F7F4F7">
                            <td
                              style={{
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s4"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "2pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                {" "}
                                {props?.name}
                                {props?.variantMem || props?.variantColor
                                  ? " - "
                                  : ""}
                                {props?.variantMem}
                                {props?.variantColor
                                  ? ` (${props?.variantColor})`
                                  : ""}
                              </p>
                            </td>
                            <td
                              style={{
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s4"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "2pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                {thousandCommaSeparator(props?.warrantyCost)}
                              </p>
                            </td>
                            <td
                              style={{
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s4"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "2pt",
                                  textIndent: "0pt",
                                  textAlign: "right",
                                }}
                              >
                                {props?.imei}
                              </p>
                            </td>
                            <td>
                              <p
                                className="s4"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "2pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                {"1"}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <div style={{ fontSize: "9px", color: "#666" }}>
                        <p>
                          <strong>What is not covered in the Warranty?</strong>
                        </p>
                        <ul style={{ margin: 0, paddingLeft: "10px" }}>
                          <li>
                            <strong>Physical Damage:</strong> Damages caused by
                            drops, spills, or any other physical impact.
                          </li>
                          <li>
                            <strong>Water Damage:</strong> Damages caused by
                            water/liquid accidents.
                          </li>
                          <li>
                            <strong>
                              Unauthorized Repairs or Modifications:
                            </strong>{" "}
                            If you attempt to repair or modify your phone on
                            your own or through an unauthorized service, it will
                            void the warranty.
                          </li>
                          <li>
                            <strong>Software Modifications:</strong> Making
                            unauthorized changes to the phone's software, like
                            rooting or installing custom ROMs, will also void
                            the warranty.
                          </li>
                          <li>
                            <strong>Normal Wear and Tear:</strong> Warranties
                            usually don't cover the natural wear and tear that
                            occurs with regular use over time.
                          </li>
                          <li>
                            <strong>Loss or Theft:</strong> Warranty does not
                            cover loss or theft.
                          </li>
                        </ul>
                      </div>

                      <p
                        style={{
                          textAlign: "center",
                          borderTop: "2px solid #000000",
                          borderBottom: "2px solid #000000",
                          marginTop: 30,
                          paddingTop: 5,
                          paddingBottom: 10,
                        }}
                      >
                        {country === "ke"
                          ? "0725626262"
                          : country === "ug"
                          ? "+256200906262"
                          : country === "tz"
                          ? "+255 672626262 / +255 694626262"
                          : ""}
                        &nbsp;&nbsp;
                        <a
                          href="mailto:accounts@badili.africa"
                          className="a"
                          target="_blank"
                        >
                          accounts@badili.africa
                        </a>
                        &nbsp;&nbsp;
                        {country === "ke"
                          ? "https://badili.ke/"
                          : country === "ug"
                          ? "https://badili.ug/"
                          : country === "tz"
                          ? "https://badili.co.tz/"
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProcessWarrantyMainPickups;
