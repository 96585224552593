import React, { useEffect, useState } from "react";
import { ListView } from "layouts";
import { PageHeaderComponent, Loader, Table } from "components/elements";
import { format } from "date-fns";
import { DownOutlined, MenuOutlined, ShopOutlined } from "@ant-design/icons";
import { useHttp } from "hooks";
import { Button, Dropdown, Menu, Space } from "antd";
import MakeSaleModal from "./MakeSaleModal";
import ModalService from "components/elements/modal/ModalService";
import UserService from "services/UserService";
import ReturnModal from "../sellOut/ReturnModal";
export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency:
      UserService.getCurrentUserLoginData().country === "ug"
        ? "UGX"
        : UserService.getCurrentUserLoginData().country === "ke"
        ? "KES"
        : UserService.getCurrentUserLoginData().country === "tz"
        ? "TZS"
        : UserService.getCurrentUserLoginData().country === "in"
        ? "INR" // India
        : UserService.getCurrentUserLoginData().country === "ng"
        ? "NGN" // Nigeria
        : " ",
  });

  return nf.format(number);
};
const ListProducts = () => {
  const [requests, setRequests] = useState([]);
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();
  const [rowCount, setRowCount] = useState(0);
  const [imeiSearchText, setImeiSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRepairRequest, setSelectedRepairRequest] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const openReturnDeviceModal = (repairData) => {
    ModalService.open(ReturnModal, {
      selectedSKURequests: repairData,
      refresh: getInven,
    });
  };
  const openMakeASaleModal = (repairData) => {
    ModalService.open(MakeSaleModal, {
      selectedRepairRequest: repairData,
      refresh: getInven,
    });
  };
  useEffect(() => {
    getInven();
  }, []);
  useEffect(() => {
    setRowCount(requests?.length);
  }, [requests]);
  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const handlInventoryData = ({ success, data: { requests } }) => {
    let tempData = [];
    requests?.map((el) => {
      return tempData.push({
        _id: el?._id,
        consiDate: format(new Date(el?.consignmentDate), "dd MMM yyyy"),
        model: el?.model?.modelName,
        model_id: el?.model?._id,
        variant:
          el?.variant[0]?.ram !== undefined
            ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
            : "",
        imei: el?.imei && el?.imei !== undefined ? el?.imei : "-",
        factorySerialNumber:el?.factorySerialNumber && el?.factorySerialNumber !== undefined ? el?.factorySerialNumber : "-",
        serial_number:
          el?.serialNumber && el?.serialNumber !== undefined
            ? el?.serialNumber
            : "-",
        warehouse: el?.warehouse?.name !== undefined && el?.warehouse?.name,
        inventoryId: el?.color?.inventoryId,
        assignedColor: el?.assignedColor[0]?.name ?? null,
        action: [el?._id, el?.sold, el?.model?.modelName, el?.model?._id, el],
        status: el?.current_status[0]?.name?.toUpperCase(),
      });
    });
    if (success === false) {
      setRequests([]);
    }
    setDisplayedData(tempData);
    setRequests(tempData);
  };
  const handleResetSearch = () => {
    setImeiSearchText("");
  };
  const handleRefreshListSearch = () => {
    getInven();
  };
  const getInven = async () => {
    getInventory(`get_all_sellin`, "GET", null, handlInventoryData);
  };

  const columns = [
    // {
    //   label: "ACTION",
    //   name: "action",
    //   options: {
    //     filter: false,
    //     download: false,
    //     print: false,
    //     sort: false,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "left" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           <div className="list-icons">
    //             <Dropdown
    //               overlay={
    //                 <Menu>
    //                   {/* <Menu.Item
    //                     key="6"
    //                     onClick={() => openMakeASaleModal(value[0], value[4])}
    //                   >
    //                     Make a Sale
    //                   </Menu.Item> */}
    //                   <Menu.Item
    //                     key="7"
    //                     onClick={() =>
    //                       openReturnDeviceModal(value[0], value[4])
    //                     }
    //                   >
    //                     Return Device
    //                   </Menu.Item>
    //                 </Menu>
    //               }
    //               trigger={["click"]}
    //               placement="bottomRight"
    //             >
    //               <Button
    //                 className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
    //                 icon={<MenuOutlined />}
    //               />
    //             </Dropdown>
    //           </div>
    //         </>
    //       );
    //     },
    //   },
    // },
    {
      label: "Consignment Date",
      name: "consiDate",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <h6 className="mb-0">
                {format(new Date(value), "dd")} {format(new Date(value), "MMM")}
              </h6>
              <div className="font-size-sm text-muted line-height-1">
                {format(new Date(value), "yyyy")}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "model_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "model",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },

    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? <b>{value}</b> : "-"}</>;
        },
      },
    },
    {
      label: "Factory Serial Number",
      name: "factorySerialNumber",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? value : "-";
          }
        },
      },
    },
    {
      label: "Color",
      name: "assignedColor",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : <p>Color not set</p>}</>;
        },
      },
    },
    {
      label: "Warehouse",
      name: "warehouse",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? <b>{value}</b> : "-"}</>;
        },
      },
    },
  ];
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => requests[dataIndex]
    );
    let tempObjData = [];
    selectedData?.map((data, zind) => {
      // tempObjData2.push({
      //   serialNumber: data?.serialNumber,
      //   skuName: data?.skuName,
      //   zind: zind + 1,
      // });
      tempObjData.push({
        _id: data?._id,
        model_id: data?.model_id,
        imei: data?.imei,
        model: data?.model,
        variant: data?.variant,
        color: data?.assignedColor,
        device_id: data?.inventoryId,
      });
    });
    setSelectedRepairRequest(tempObjData);
  };

  const handleImeiSearch = () => {
    // Splitting IMEI text by comma or newline
    const imeis = imeiSearchText
      .split(/[,\n]/)
      .map((imei) => imei.trim())
      .filter((imei) => imei !== "");
    // Perform search using the arrays of IMEIs and serial numbers
    const filteredData = displayedData?.filter((item) =>
      imeis?.some((imei) => item?.imei?.includes(imei))
    );
    setRequests(filteredData);
    setSelectedRowsData([]); // Clear selected rows
    setSelectedRepairRequest([]); // Clear selected repair requests
  };
  return (
    <>
      {loadingInventory && <Loader />}

      {!loadingInventory && (
        <ListView
          pageHeader={
            <PageHeaderComponent
              title="Sales Supervisor Dashboard"
              subtitle=""
              icon="icofont-home"
              extras={
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="mb-3 mb-md-0">
                      <div className="ml-3 text-center">
                        <h3 className="font-weight-semibold mb-0">
                          {requests?.length}
                        </h3>
                        <span>Available Devices</span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              }
            />
          }
          table={
            <>
              <div style={{ textAlign: "center" }}>
                <textarea
                  type="text"
                  value={imeiSearchText}
                  onChange={(e) => setImeiSearchText(e.target.value)}
                  placeholder="Enter IMEIs (comma-separated or one per line)"
                  className="form-control"
                  style={{ width: "300px", margin: "0 auto", display: "block" }}
                />{" "}
                <br />
                <button
                  type="button"
                  className="ant-btn ant-btn-primary"
                  onClick={handleImeiSearch}
                  style={{ marginLeft: "10px" }}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="ant-btn ant-btn-ghost"
                  onClick={handleResetSearch}
                  style={{ marginLeft: "10px" }}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="ant-btn ant-btn-ghost"
                  onClick={handleRefreshListSearch}
                  style={{ marginLeft: "10px" }}
                >
                  Refresh List
                </button>
                <hr />
              </div>
              <Table
                title={"Sell In Allocation"}
                data={requests}
                columns={columns}
                option={{
                  customToolbar: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "16px",
                          fontSize: "18px",
                          marginLeft: "-58px",
                        }}
                      >
                        Total Rows: <b>{rowCount}</b>
                      </div>
                    </div>
                  ),
                  onTableChange: (action, tableState) => {
                    if (action === "filterChange" || action === "search") {
                      setFilteredData(
                        tableState.displayData.map((row) => row.data)
                      );
                    }
                  },
                  selectableRows: "multiple",
                  onRowSelectionChange: handleRowSelectionChange,
                  customToolbarSelect: (
                    selectedRows,
                    displayData,
                    setSelectedRows
                  ) => {
                    const actions = [
                      {
                        name: "Make Bulk Sale",
                        onClick: () =>
                          openMakeASaleModal(selectedRepairRequest),
                      },
                      {
                        name: "Bulk Return",
                        onClick: () =>
                          openReturnDeviceModal(selectedRepairRequest),
                      },
                    ];
                    const menu = (
                      <Menu>
                        {actions.map((action, index) => (
                          <Menu.Item key={index} onClick={action.onClick}>
                            {action.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    );
                    return (
                      <div>
                        <Dropdown overlay={menu}>
                          <Button>
                            <Space>
                              Actions
                              <DownOutlined />
                            </Space>
                          </Button>
                        </Dropdown>
                        &emsp;
                      </div>
                    );
                  },
                }}
              />
            </>
          }
          data={requests}
        />
      )}
    </>
  );
};

export default ListProducts;
