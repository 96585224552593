import React, { useState } from "react";
import { Form, Input, Modal as AntModal, Select } from "antd";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import UserService from "services/UserService";

const { Option } = Select;
const defectCategories = {
  AUDIO: [
    "Earphone Not Working",
    "Earpiece Crackling",
    "Mic Not Working",
    "Speaker Crackling",
    "Speaker Not Working",
  ],
  BATTERY: ["Battery Bulge / Swell", "Battery Drains Fast"],
  CAMERA: [
    "Camera Dots",
    "Camera Blur Image",
    "Flash Light Not Working",
    "Front Camera Not Working",
    "Rear Camera Not Functioning",
  ],
  CHANNEL_RETURN: [
    "Customer Preference Changed",
    "Delivery Failed",
    "Re-Qc",
    "Sales Stock Recall",
    "Wrong Item Description",
  ],
  CHARGING: [
    "Charging Adapter Not Working",
    "Charging Port Loose",
    "Not Charging",
    "Charging Cable Not Working",
  ],
  CONNECTIVITY: ["Wifi Not Working", "Bluetooth Not Working"],
  COSMETIC: [
    "Backcover Peeling",
    "Broken Backglass",
    "Cracked Screen",
    "Dented Backcover",
    "Dented Body",
    "Dented Frame",
    "Gapping",
    "Missing Camera Lens",
    "Scratched Backcover",
    "Scratched Frame",
  ],
  DISPLAY: [
    "Display Blank",
    "Display Flickering",
    "Display Not Responding",
    "Non-Original LCD",
    "Touch Not Working",
  ],
  KEYS: [
    "Sidekey Hard",
    "Sidekey Missing",
    "Keys Do Not Work",
    "Power Button Hard",
  ],
  LOCKS: [
    "Pattern Or Pin Locks",
    "Samsung Account Locks",
    "Mkopa Locked",
    "Network Lock Code",
  ],
  NETWORK: [
    "Poor Network Connectivity",
    "Poor Network Reception/Bad Receiving",
    "5G Network Not Working",
    "Cellular Network Not Working",
    "Data Connectivity Issues",
  ],
  OVERHEAT: ["Device Overheating"],
  PHYSICAL_DAMAGES: [
    "Fell In Water",
    "Screen Damaged / Broken",
    "Back Cover Broken",
  ],
  POWER: [
    "Phone Switches Off",
    "Switches Off - Intermittently",
    "Boot Failure",
    "Does Not Switch On",
    "Phone Hanging",
    "Phone Restarts / Reboots",
  ],
  MEMORY: ["Internal Memory Size Mismatch", "Sd Card Not Working"],
  SENSORS: [
    "Fingerprint Sensor Not Working",
    "Proximity Sensor Not Working",
    "Face Biometrics Not Working",
  ],
  SIM: [
    "Sim Toolkit Not Working",
    "Sim Tray Missing",
    "Sim Card Not Ejecting",
    "Sim Card Not Reading",
  ],
  SOFTWARE: [
    "Phone Language Issues",
    "Software Problem",
    "Whatsapp Not Working",
    "App Crashing / Not Working",
    "Device Rooted / Jailbroken",
    "Docomo Logo In Booting",
  ],
  TOUCH: [
    "Touch Screen Auto Work",
    "Touch Screen Not Working",
    "Touch Screen Sensitivity",
  ],
  VIBRATOR: ["Vibrator Not Working"],
  IMEI: ["Wrong Imei Captured"],
  LOGISTICS: ["Stock Recall"],
};
export default function ReturnModal(props) {
  const { toastDispatch } = useToastContext();
  const [form] = Form.useForm();
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDefects, setSelectedDefects] = useState([]);
  const { error, loading, sendHttpRequest: assignQC } = useHttp();

  // const defectOptions = [
  //   "Does Not Switch On",
  //   "Blank Display",
  //   "Touch Screen Not Working",
  //   "Not Charging",
  //   "Device Overheating",
  //   "Battery Drains Fast",
  //   "Speaker Not Working",
  //   "Mic Not Working",
  //   "Speaker Crackling",
  //   "Earpiece Crackling",
  //   "Battery Bulge/Swell",
  //   "Screen Color Issue",
  //   "Line On Screen",
  //   "Phone Restarts / Reboots",
  //   "Phone Hanging",
  //   "Screen Damaged",
  //   "Dent On The Screen",
  //   "Sim Card Not Reading",
  //   "Vibrator Not Working",
  //   "App Crashing",
  //   "User / Frp Locks",
  //   "Camera Blur Image",
  //   "Camera Not Functioning",
  //   "Flash Light Not Working",
  //   "Cellular Network Not Working",
  //   "Data Connectivity Issues",
  //   "Poor Network Reception/Bad Receiving",
  //   "Wifi Not Working",
  //   "Bluetooth Not Working",
  //   "Backcover Poor Fit",
  //   "Screen Poor Fit",
  //   "Cosmetics Scratched",
  //   "Screen Flickering",
  //   "Screen Broken",
  //   "Dust/ Dirt/Rust Inside",
  //   "Keys Do Not Work",
  //   "Sidekey Missing",
  //   "Sidekey Hard",
  //   "Memory Card Not Working",
  //   "Poor Network Connectivity",
  //   "Boot Failure",
  //   "Phone Switches Off",
  //   "Fingureprint Sensor Not Working",
  //   "Proximity Sensor Not Working",
  //   "Touch Screen Auto Work",
  //   "Backcover Broken",
  //   "Backcover Scratches",
  //   "Sim Tray Missing",
  //   "Software Problem",
  //   "Screen Burnt",
  //   "Power Button Hard",
  //   "Frame Dented",
  //   "Wrong Item Description",
  //   "Deliver Failed",
  //   "Customer Preference",
  //   "RE-QC",
  //   "Stock Recall",
  //   "Face Biometrics Not Working",
  //    "Warranty Repair",
  //   "Repair Pro"
  // ];

  const handleInitialFinish = (values) => {
    setShowPasswordField(true);
  };

  const handleConfirmSubmit = (values) => {
    const data = {
      remarks: remarks,
      defects: selectedDefects,
      category: selectedCategory,
      password: values.password,
      InventoryId: props?.InventoryId?._id,
      userId: UserService.getCurrentUserLoginData().id,
    };

    assignQC("return_from_sellout", "PUT", data, ({ msg }) => {
      props.close();
      props.refresh();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => form.submit()}
      onReject={props.close}
      title="Return Request"
      form="makeSale"
      loading={loading}
      AcceptText={!showPasswordField ? "Submit" : "Confirm and Submit"}
    >
      <Form
        layout="vertical"
        id="makeSale"
        form={form}
        onFinish={
          !showPasswordField ? handleInitialFinish : handleConfirmSubmit
        }
        onFinishFailed={onFinishFailed}
      >
        {!showPasswordField ? (
          <>
            <p>Are you sure you want to request return?</p>

            <Form.Item
              label="Defect Category"
              name="defectCategory"
              rules={[
                {
                  required: true,
                  message: "Please select a defect category",
                },
              ]}
            >
              <Select
                placeholder="Select defect category"
                onChange={(value) => {
                  setSelectedCategory(value);
                  setSelectedDefects([]);
                  form.setFieldValue("defects", []);
                }}
              >
                {Object.keys(defectCategories).map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Defect Names"
              name="defects"
              rules={[
                {
                  required: true,
                  message: "Please select at least one defect",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select defects"
                value={selectedDefects}
                onChange={setSelectedDefects}
                allowClear
                disabled={!selectedCategory}
              >
                {selectedCategory &&
                  defectCategories[selectedCategory]?.map((defect) => (
                    <Option key={defect} value={defect}>
                      {defect}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[
                {
                  required: true,
                  message: "Please input your remarks",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter your remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Form.Item>
          </>
        ) : (
          <>
            <p>Are you sure you want to submit?</p>
            <Form.Item
              label="Enter your password to authorize"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
