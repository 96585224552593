import SellOutRoutes from "./SellOut";

const SellOutRoute = [
  {
    path: `/get-all-sell-out`,
    element: <SellOutRoutes />,
  },
];

export default SellOutRoute;
