import React from "react";
import WarrantyPickup from "./WarrantyPickup";

export const WarrantyPickupRoutes = [
  {
    path: `/dashboard`,
    exact: true,
    element: <WarrantyPickup />,
  },
];
