import CustomerApproval from "./CustomerApproval";
import WarrantyPickups from "./WarrantyMainPickups";
import ListProducts from "./warrenty";

const WarrInRoutes = [
  
 
  {
    path: `/pickup-request`,
    element: <WarrantyPickups />,
  },
  {
    path: `/warranty-collection`,
    element: <ListProducts />,
  },
  {
    path: `/customer-approval`,
    element: <CustomerApproval />,
  },
];

export default WarrInRoutes;
