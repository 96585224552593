import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFetch, useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";

export default function BAForgotPassword() {
  const navigate = useNavigate();
  const { toastDispatch } = useToastContext();
  
  const { error, loading, sendHttpRequest: forgotPass } = useHttp();

  const [credentials, setCredentials] = useState({
    email: "",
    // permission: "",
    country: "",
  });

  const onChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };
  // handleRolesData((data) => {
  //   setRoles(data.roles);
  // });

  const requestReset = async (e) => {
    e.preventDefault();
    forgotPass(
      "forgotPassword_ba",
      "POST",
      {
        email: credentials.email,
        // role: credentials.permission,
        source: 'ba',
        role: 8,
        country: credentials.country,
      },
      ({ msg,success }) => {
        if(success){
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          setCredentials({
            email: "",
            // permission: "",
            country: "",
          });
        }
      }
    );
  };

  return (
    <>
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="login-card">
                <form
                  className="theme-form login-form"
                  method="post"
                  onSubmit={requestReset}
                >
                  <h4>Forgot your password?</h4>
                  <h6>Please enter the email you use to sign in</h6>
                  <div className="form-group">
                    <label>Select your Country</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icofont icofont-flag"></i>
                      </span>
                      <select
                        className="form-control"
                        name="country"
                        placeholder="Select a country"
                        onChange={onChange}
                        required
                      >
                        <option value="">Select a country</option>
                        <option value="ke">
                          {" "}
                          {"\uD83C\uDDF0\uD83C\uDDEA"} Kenya
                        </option>
                        <option value="ug">
                          {"\uD83C\uDDFA\uD83C\uDDEC"} Uganda
                        </option>
                        <option value="tz">
                          {"\u{1f1f9}\u{1f1ff}"} Tanzania
                        </option>
                        <option value="in">
                          {"\uD83C\uDDEE\uD83C\uDDF3"} India
                      </option>
                      <option value="ng">
                              {"\uD83C\uDDF3\uD83C\uDDEC"} Nigeria
                            </option>
                      </select>
                    </div>
                  </div>
                
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icofont icofont-envelope"></i>
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="your email"
                        onChange={onChange}
                        required
                        disabled={!credentials.country}
                      />
                    </div>
                  </div>

                  <div className="form-group mt-5">
                    <button
                      className="btn btn-primary btn-block ld-ext-right text-capitalize"
                      type="submit"
                      // onClick={() => navigate("/reset-password")}
                      disabled={loading}
                    >
                      Request Password Reset
                      {loading && (
                        <>
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      )}
                    </button>
                  </div>
                  <p className="text-center">
                    {" "}
                    <Link to="/ba-login">Back to Sign In</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}