import React, { useState, useContext } from "react";
import { Form, Input, Upload, Radio, Row, Col } from "antd";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { ADD, useToastContext } from "context/ToastContext";
import UserService from "services/UserService";
import { AppContext } from "context/AppContext";
import { ImageUploader } from "components/elements";
import Compressor from "compressorjs";

const fileList = [
  { uid: "-11", name: "front", label: "Front", url: "", file: null },
  { uid: "-12", name: "back", label: "Back", url: "", file: null },
  // { uid: "-13", name: "imei_displayed_image", label: "Side", url: "", file: null },
  // { uid: "-14", name: "screen_on_image", label: "Device ON", url: "", file: null },
];

export default function ProcessWarrantyModal(props) {
  const { url } = useContext(AppContext);
  const { toastDispatch } = useToastContext();
  const [customerIssues, setCustomerIssues] = useState("");
  const [physicalDamageAgreement, setPhysicalDamageAgreement] = useState(null);
  const [form] = Form.useForm();
  const { loading: loadingPost, sendHttpRequest: addNewAsserts } = useHttp();
  const listName = props?.name;
  const productImeiName = props?.imei;

  const handleData = ({ success, msg }) => {
    if (success) {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    }
  };

  const onFinish = (values) => {
    addNewAsserts(
      `process_warranty_pickup`,
      "PUT",
      {
        id: props?.id,
        partnerId: UserService?.getCurrentUserLoginData()?.partnerId,
        userId: UserService?.getCurrentUserLoginData()?.id,
        customerIssues,
        physicalDamageAgreement,
        password: values?.password,
        //file: [url],
      },
      handleData
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={form.submit}
      onReject={props.close}
      title={"Process Warranty Pickup"}
      form="nakeAsale"
      loading={loadingPost}
      AcceptText="Submit"
    >
      <Form
        layout="vertical"
        id="nakeAsale"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <p>
          Would you like to process device <b>({productImeiName})</b> for
          warranty pickup?
        </p>
        <hr></hr>
        <Form.Item
          label="Describe the Customer Reported Issues"
          name="customerIssues"
        >
          <Input.TextArea
            placeholder="Enter details about the reported issues"
            value={customerIssues}
            onChange={(e) => setCustomerIssues(e.target.value)}
          />
        </Form.Item>
        {/* <p>
          <b>Upload Phone images</b>
        </p>
        <Row>
          {fileList.map(({ uid, name, label }) => (
            <Col span={6}>
              <Form.Item
                key={uid}
                name={name}
                rules={[
                  {
                    required: true,
                    message: `${label} is required`,
                  },
                ]}
              >
                <ImageUploader key={uid} name={name} description={label} />
              </Form.Item>
            </Col>
          ))}
        </Row> */}
        <Form.Item label="In case of Physical Damage, Customer Ready to Pay the Cost of Repair">
          <Radio.Group
            onChange={(e) => setPhysicalDamageAgreement(e.target.value)}
            value={physicalDamageAgreement}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Enter your password to authorize"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
          ]}
        >
          <Input type="password" placeholder="Enter your password" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
