import React, { useEffect, useState } from "react";
import { ListView } from "layouts";
import { PageHeaderComponent, Loader, Table } from "components/elements";
import { format } from "date-fns";
import { MenuOutlined, ShopOutlined } from "@ant-design/icons";
import { useHttp } from "hooks";
import { Image, Modal, Menu, Dropdown, Button } from "antd";
import ModalService from "components/elements/modal/ModalService";
import UserService from "services/UserService";
import ProcessWarrantyModal from "./ProcessWarrantyModal";

export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency:
      UserService.getCurrentUserLoginData().country === "ug"
        ? "UGX"
        : UserService.getCurrentUserLoginData().country === "ke"
        ? "KES"
        : UserService.getCurrentUserLoginData().country === "tz"
        ? "TZS"
        : UserService.getCurrentUserLoginData().country === "in"
        ? "INR" // India
        : UserService.getCurrentUserLoginData().country === "ng"
        ? "NGN" // Nigeria
        : " ",
  });

  return nf.format(number);
};
const WarrantyPickups = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getWarrantytory,
  } = useHttp();
  const openProcessWarrantyModal = (val) => {
    ModalService.open(ProcessWarrantyModal, {
      val,
      refresh: getWarranty,
    });
  };

  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getWarranty();
  }, []);
  const handlePreview = (image) => {
    setPreviewImage(image);
    setPreviewVisible(true);
  };

  const handlInventoryData = ({ data: { warranty } }) => {
    let tempData = [];
    warranty?.map((val) => {
      return tempData.push({
        name: val?.model?.[0]?.modelName || "-", // Safe navigation for `model`
        imei: val?.imei || "-",
        _id: val?._id || "-",
        purchase_Date: val?.purchaseDate || "-",
        warranty_Date: val?.warrantyDate || "-",
        status: val?.status || "-", // Default to false if undefined
        customername: `${val?.fname || ""} ${val?.lname || ""}`.trim(),
        customercontact: val?.phone || "-",
        customeremail: val?.email || "-",
        customerIssues: val?.customerIssues || "-",
        sales_agent: `${val?.sales_agent?.fname || ""} ${
          val?.sales_agent?.lname || ""
        }`.trim(),
        physicalDamageAgreement: val?.physicalDamageAgreement,
        warrUploadImg: val?.upload,
        action: {
          _id: val?._id,
          name: val?.model?.[0]?.modelName,
          orderNumber: val?.orderNumber,
          phone: val?.phone,
          cname: `${val?.fname || ""} ${val?.lname || ""}`.trim(),
          warehouse: val?.warehouse?.name,
          orderNumber: val?.orderNumber,
          sales_agent: `${val?.sales_agent?.fname || ""} ${
            val?.sales_agent?.lname || ""
          }`.trim(),
          sales_agent_phone: `${val?.sales_agent?.phone || ""}`,
          modelName: val?.model?.[0]?.modelName || "-",
          variant: val?.variant?.length
            ? `${val?.variant[0]?.ram || "-"}GB/${
                val?.variant[0]?.storage || "-"
              }${val?.variant[0]?.storageType || ""}`
            : "-",
          imei: val?.imei || "-",
          physicalDamageAgreement: val?.physicalDamageAgreement,
          soldDate: val?.inventory?.soldDate ? val?.inventory?.soldDate : "-",
          serialNumber: val?.inventory?.serialNumber,
          customerIssues: val?.customerIssues,
        },
      });
    });
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: warranty.length,
    });
  };

  const getWarranty = () => {
    getWarrantytory(
      `warranty_ready_for_processing`,
      "GET",
      null,
      handlInventoryData
    );
  };

  const columns = [
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="7"
                        onClick={() => openProcessWarrantyModal(value)}
                      >
                        Process Warranty
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    icon={<MenuOutlined />}
                  />
                </Dropdown>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Product Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "PRODUCT IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Images",
      name: "warrUploadImg",
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value) => {
          if (!value || value.length === 0) return "-";
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              {value.map((img, index) => (
                <Image
                  key={index}
                  src={img.image}
                  width={40}
                  height={40}
                  style={{ cursor: "pointer", borderRadius: "5px" }}
                  onClick={() => handlePreview(img.image)}
                  preview={false}
                />
              ))}
            </div>
          );
        },
      },
    },
    {
      label: "Customer Issues",
      name: "customerIssues",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Physical Damage Agreement",
      name: "physicalDamageAgreement",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? "Yes" : "No";
        },
      },
    },
    {
      label: "STATUS",
      name: "status",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <b>{value}</b>;
        },
      },
    },
    {
      label: "Sales Agent",
      name: "sales_agent",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "PURCHASE DATE",
      name: "purchase_Date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    {
      label: "WARRANTY DATE",
      name: "warranty_Date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },

    {
      label: "CUSTOMER NAME",
      name: "customername",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER CONTACT",
      name: "customercontact",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER EMAIL",
      name: "customeremail",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
      },
    },
  ];

  return (
    <>
      {loadingInventory && <Loader />}

      {!loadingInventory && (
        <ListView
          pageHeader={
            <PageHeaderComponent
              title="Pickup Request"
              subtitle=""
              icon="icofont-vehicle-delivery-van"
              extras={
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="mb-3 mb-md-0">
                      <div className="ml-3 text-center">
                        <h3 className="font-weight-semibold mb-0">
                          {pageData?.totalProducts}
                        </h3>
                        <span>Available Devices</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          }
          table={
            <Table
              title={"Pickup Request"}
              data={pageData.rowData}
              columns={columns}
            />
          }
          data={pageData.rowData}
        />
      )}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="preview" style={{ width: "80%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default WarrantyPickups;
