import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Upload, Button, Radio } from "antd";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import { UploadOutlined } from "@ant-design/icons";
import UserService from "services/UserService";
import "./DownloadDeliveryNote.css";
import ImageLogoBadili from "../../../../assets/images/pdfLogo/badili-logo.jpg";
import html2pdf from "html2pdf.js";
import { format } from "date-fns";
export default function AssignRTSModal(props) {
  const { toastDispatch } = useToastContext();
  const [fileList, setFileList] = useState([]);
  const pdfRef = useRef(null);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [showPasswordField, setShowPasswordField] = useState(false);
  const { error, loading, sendHttpRequest: assignQC } = useHttp();
  const { werror, wloading, sendHttpRequest: getOrderNumber } = useHttp();
  const [OrderNumber, setOrderNumber] = useState("");
  const [needDeliveryNote, setNeedDeliveryNote] = useState(false);
  const country = UserService.getCurrentUserLoginData().country;
  useEffect(() => {
    getOrderNumber(
      `dtc_order_number/${country}`,
      "GET",
      null,
      ({ data: { note } }) => {
        console.log(note);
        setOrderNumber(note);
      }
    );
  }, []);
  const handleCheckboxChange = (e) => {
    setNeedDeliveryNote(e.target.value === "yes");
  };

  const handleDownloadPDF = () => {
    const randomNumber = Math.floor(Math.random() * 1000); // Adjust as needed
    const filename = `downloaded-sale-delivery-note-${randomNumber}.pdf`;
    const htmlContent = document.getElementById("htmlContentToConvert");
    const options = {
      margin: 5, // Adjust margin
      filename: filename,
      html2canvas: { scale: 1.5 }, // Adjust scale
      pagebreak: { mode: "avoid-all" },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    if (htmlContent) {
      html2pdf().from(htmlContent).set(options).save();
    } else {
      console.error("HTML content not found. Unable to generate PDF.");
    }
  };

  const handleInitialFinish = (values) => {
    setShowPasswordField(true);
  };
  const handleConfirmSubmit = (values) => {
    const data = {
      InventoryId: props?.InventoryId?._id,
      customerFirstName: form.getFieldValue("customerFirstName"),
      customerLastName: form.getFieldValue("customerLastName"),
      email: form.getFieldValue("email"),
      phoneNumber: form.getFieldValue("phoneNumber"),
      warrantyUpload: fileList[0]?.base64,
      password: values?.password,
      userId: UserService?.getCurrentUserLoginData()?.id,
      needDeliveryNote: needDeliveryNote,
      address: form.getFieldValue("address"),
    };
    assignQC("/make_sale", "POST", data, ({ msg }) => {
      props.close();
      props.refresh();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
    if (needDeliveryNote) {
      return handleDownloadPDF();
    }
  };
  const handleUpload = ({ fileList }) => {
    fileList.forEach((file, index) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });
    setFileList(fileList);
  };
  const removeUpload = () => {
    form.setFieldsValue({ warrantyUpload: "" });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Modal
      close={props.close}
      onAccept={() => form.submit()}
      onReject={props.close}
      title="Make a Sale"
      form="makeSale"
      loading={loading}
      size="lg"
      AcceptText={
        !showPasswordField
          ? "Next"
          : needDeliveryNote === false
          ? "Submit"
          : "Download and Submit"
      }
    >
      <Form
        layout="vertical"
        id="makeSale"
        initialValues={{ needDeliveryNote: "no" }}
        form={form}
        onFinish={
          !showPasswordField ? handleInitialFinish : handleConfirmSubmit
        }
        onFinishFailed={onFinishFailed}
      >
        {!showPasswordField ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <Form.Item
                  label="Customer First Name"
                  name="customerFirstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input the customer's first name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  label="Customer Last Name"
                  name="customerLastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input the customer's last name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>{" "}
              <div className="col-md-4">
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input the phone number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>{" "}
              <div className="col-md-4">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: false,
                      type: "email",
                      message: "Please input a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>{" "}
              <div className="col-md-4">
                <Form.Item label="Warranty Upload" name="warrantyUpload">
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    required
                    maxCount={1}
                    onChange={handleUpload}
                    beforeUpload={() => false}
                    onRemove={() => removeUpload()}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  label="Do you need a delivery note?"
                  name="needDeliveryNote"
                >
                  <Radio.Group onChange={handleCheckboxChange}>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              {needDeliveryNote && (
                <div className="col-md-12">
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please input the address",
                      },
                    ]}
                  >
                    <TextArea rows={2} />
                  </Form.Item>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-6">
                {/* <b>Are you sure you want to submit?</b> */}
                <Form.Item
                  label="Enter your password to authorize"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
              </div>
            </div>
            <hr />
            {needDeliveryNote && (
              <div id="htmlContentToConvert" className="scrllView" ref={pdfRef}>
                <div className="receipt-content ">
                  <div className="container bootstrap snippets bootdey">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="invoice-wrapper">
                          <div
                            className="intro"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={ImageLogoBadili}
                              width={155}
                              height={65}
                              alt="Badili Logo"
                            />
                            <div style={{ marginLeft: "20px" }}>
                              {country === "ke" && (
                                <>
                                  <p className="ft16">Kenya;</p>{" "}
                                  <p className="ft16">P.O Box 8744-00100,</p>
                                  <p className="ft16"> Nairobi.</p>
                                  <p className="ft16">
                                    {" "}
                                    Chiromo Court, Waiyaki Way
                                  </p>
                                </>
                              )}
                              {country === "ug" && (
                                <>
                                  <p className="ft16">Badili Uganda SMC Limited</p>
                                  <p className="ft16">
                                    Office No 301 , Level 3- Kampala Boulevard
                                  </p>
                                  <p className="ft16">
                                    {" "}
                                    Kampala Road - Kampala, Uganda.
                                  </p>
                                </>
                              )}
                              {country === "tz" && (
                                <>
                                  <p className="ft16"> Badili Tanzania LTD</p>{" "}
                                  <p className="ft16"> Chole Plaza,</p>
                                  <p className="ft16"> 26 Chole Rd, </p>
                                  <p className="ft16">
                                    {" "}
                                    Dar es Salaam -Tanzania
                                  </p>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="payment-details">
                            <p
                              className="s3"
                              style={{
                                //  paddingTop: "1pt",
                                textAlign: "center",
                              }}
                            >
                              <h6 style={{ textDecoration: "underline" }}>
                                Delivery Note
                              </h6>
                            </p>
                            <div className="row">
                              <div className="col-sm-6">
                                <p
                                  className="s2"
                                  style={{
                                    paddingLeft: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                  }}
                                >
                                  {props?.transfer?.deliveryId}
                                </p>
                              </div>
                              <div className="col-sm-6 delAddess">
                                <div>
                                  <strong>Address</strong>:{" "}
                                  {form.getFieldValue("address")}
                                  <br />
                                  <strong>Customer Name</strong>:{" "}
                                  {form.getFieldValue("customerFirstName")}{" "}
                                  {form.getFieldValue("customerLastName")}
                                  <br />
                                  <strong>Customer Phone</strong>:{" "}
                                  {form.getFieldValue("phoneNumber")}
                                  <br />
                                  {form.getFieldValue("email") && (
                                    <>
                                      <strong>Customer Email</strong>:{" "}
                                      {form.getFieldValue("email")}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-6">
                              <strong>Order: </strong>
                              <br />
                              <p>{OrderNumber}</p>
                            </div>
                            <div className="col-sm-6">
                              <strong>Shipping Date: </strong>
                              <br />
                              <p>{format(new Date(), "dd-MMM-yyyy HH:mm a")}</p>
                            </div>
                          </div>
                          <div className="line-items">
                            <table
                              style={{
                                borderCollapse: "collapse",
                                marginLeft: "-7.2695pt",
                                marginBottom: "140px",
                              }}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr style={{ height: "21pt" }}>
                                  <td
                                    style={{
                                      width: "329pt",
                                      borderTopStyle: "solid",
                                      borderTopWidth: "1pt",
                                      borderTopColor: "#dde1e5",
                                      borderLeftStyle: "solid",
                                      borderLeftWidth: "1pt",
                                      borderLeftColor: "#dde1e5",
                                      borderBottomStyle: "solid",
                                      borderBottomWidth: "2pt",
                                      borderBottomColor: "#484f56",
                                      borderRightStyle: "solid",
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s3"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "144pt",
                                        paddingRight: "144pt",
                                        textIndent: "0pt",
                                        textAlign: "center",
                                      }}
                                    >
                                      <strong>Product</strong>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      width: "133pt",
                                      borderTopStyle: "solid",
                                      borderTopWidth: "1pt",
                                      borderTopColor: "#dde1e5",
                                      borderLeftStyle: "solid",
                                      borderLeftWidth: "1pt",
                                      borderLeftColor: "#dde1e5",
                                      borderBottomStyle: "solid",
                                      borderBottomWidth: "2pt",
                                      borderBottomColor: "#484f56",
                                      borderRightStyle: "solid",
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s3"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "24pt",
                                        textIndent: "0pt",
                                        textAlign: "left",
                                      }}
                                    >
                                      <strong>IMEI</strong>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      width: "74pt",
                                      borderTopStyle: "solid",
                                      borderTopWidth: "1pt",
                                      borderTopColor: "#dde1e5",
                                      borderLeftStyle: "solid",
                                      borderLeftWidth: "1pt",
                                      borderLeftColor: "#dde1e5",
                                      borderBottomStyle: "solid",
                                      borderBottomWidth: "2pt",
                                      borderBottomColor: "#484f56",
                                      borderRightStyle: "solid",
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s3"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "14pt",
                                        paddingRight: "13pt",
                                        textIndent: "0pt",
                                        textAlign: "center",
                                      }}
                                    >
                                      <strong>Units</strong>
                                    </p>
                                  </td>
                                </tr>

                                <tr bgcolor="#F7F4F7">
                                  <td
                                    style={{
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s4"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "2pt",
                                        textIndent: "0pt",
                                        textAlign: "left",
                                      }}
                                    >
                                      {(() => {
                                        let formattedString = "";

                                        if (
                                          props?.InventoryId?.model?.modelName
                                        ) {
                                          formattedString += `${props?.InventoryId?.model?.modelName}`;
                                        }

                                        if (
                                          props?.InventoryId?.variant?.length
                                        ) {
                                          if (formattedString) {
                                            formattedString += " - ";
                                          }
                                          formattedString += `${
                                            props?.InventoryId?.variant[0]
                                              ?.ram !== undefined
                                              ? `${props?.InventoryId?.variant[0]?.ram}GB/${props?.InventoryId?.variant[0]?.storage}${props?.InventoryId?.variant[0]?.storageType}`
                                              : ""
                                          }`;
                                        }

                                        if (
                                          props?.InventoryId?.assignedColor
                                            ?.length
                                        ) {
                                          if (formattedString) {
                                            formattedString += " - ";
                                          }
                                          formattedString += `(${props?.InventoryId?.assignedColor[0]?.name})`;
                                        }

                                        return formattedString;
                                      })()}
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s4"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "2pt",
                                        textIndent: "0pt",
                                        textAlign: "right",
                                      }}
                                    >
                                      {props?.InventoryId?.imei}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="s4"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "2pt",
                                        textIndent: "0pt",
                                        textAlign: "center",
                                      }}
                                    >
                                      {"1"}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div>
                          <div style={{ fontSize: "9px", color: "#666" }}>
                        <p>
                          <strong>What is not covered in the Warranty?</strong>
                        </p>
                        <ul style={{ margin: 0, paddingLeft: "10px" }}>
                          <li>
                            <strong>Physical Damage:</strong> Damages caused by
                            drops, spills, or any other physical impact.
                          </li>
                          <li>
                            <strong>Water Damage:</strong> Damages caused by
                            water/liquid accidents.
                          </li>
                          <li>
                            <strong>
                              Unauthorized Repairs or Modifications:
                            </strong>{" "}
                            If you attempt to repair or modify your phone on
                            your own or through an unauthorized service, it will
                            void the warranty.
                          </li>
                          <li>
                            <strong>Software Modifications:</strong> Making
                            unauthorized changes to the phone's software, like
                            rooting or installing custom ROMs, will also void
                            the warranty.
                          </li>
                          <li>
                            <strong>Normal Wear and Tear:</strong> Warranties
                            usually don't cover the natural wear and tear that
                            occurs with regular use over time.
                          </li>
                          <li>
                            <strong>Loss or Theft:</strong> Warranty does not
                            cover loss or theft.
                          </li>
                        </ul>
                      </div>
                            <p
                              style={{
                                textAlign: "center",
                                borderTop: "2px solid #000000",
                                borderBottom: "2px solid #000000",
                                marginTop: 30,
                                paddingTop: 5,
                                paddingBottom: 10,
                              }}
                            >
                              {country === "ke"
                                ? "0725626262"
                                : country === "ug"
                                ? "+256200906262"
                                : country === "tz"
                                ? "+255 672626262 / +255 694626262"
                                : ""}
                              &nbsp;&nbsp;
                              <a
                                href="mailto:accounts@badili.africa"
                                className="a"
                                target="_blank"
                              >
                                accounts@badili.africa
                              </a>
                              &nbsp;&nbsp;
                              {country === "ke"
                                ? "https://badili.ke/"
                                : country === "ug"
                                ? "https://badili.ug/"
                                : country === "tz"
                                ? "https://badili.co.tz/"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
}
